import intlTelInput from 'intl-tel-input';
import IMask from 'imask';

document.addEventListener('DOMContentLoaded', function () {
    const input = document.querySelector('.intl-tel-input');

    if (input) {
        const telInputConfig = {
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.2.1/js/utils.min.js',
            initialCountry: 'US',
            nationalMode: false,
        };

        const telInput = intlTelInput(input, telInputConfig);

        let mask;
        let alertShown = false;

        telInput.promise.then(() => {
            const inputPlaceholder = input.getAttribute('placeholder');
            const pattern = transformPhoneNumber(inputPlaceholder);

            mask = IMask(input, { mask: pattern, lazy: false });
        });

        input.addEventListener('countrychange', function () {
            alertShown = false;

            const inputPlaceholder = input.getAttribute('placeholder');
            if (!inputPlaceholder) {
                return;
            }
            const pattern = transformPhoneNumber(inputPlaceholder);

            mask.updateOptions({ mask: pattern, lazy: false });
            mask.value = '';
        });

        input.addEventListener('blur', function () {
            if (alertShown) return;

            const inputValueLength = input.value.replace(/\D/g, '').length;
            const maskedLength = mask.masked.mask.replace(/\D/g, '').length;

            if (inputValueLength === maskedLength && inputValueLength > 0) {
                //alert("End of enter tel");
                alertShown = true;
            }
        });
    }
});

function transformPhoneNumber(phoneNumber) {
    const regex = /\+(\d+)/;
    const match = phoneNumber.match(regex);

    if (match) {
        const transformedNumber = phoneNumber
            .replace(match[1], `{${match[1].replace(/0/g, '\\0')}}`)
            .replace(/(\d)(?=[^{}]*$)/g, (match) => '0'.repeat(match.length));

        const formattedNumber = transformedNumber.split(' ').join('-');
        return formattedNumber;
    } else {
        return phoneNumber;
    }
}
