import Swiper from 'swiper';
import { Navigation, Autoplay, Pagination, EffectFade } from 'swiper/modules';
import { getSwiperNavigation } from './utils/getSwiperNavigation';

document.addEventListener('DOMContentLoaded', function () {
    const swiperMentor = new Swiper('.swiper--mentors', {
        slidesPerView: 'auto',
        spaceBetween: 5,
        freeMode: true,
        loop: true,
        breakpoints: {
            960: {
                spaceBetween: 30,
            },
        },
    });

    const swiperTrustCompanies = new Swiper('.swiper--trust-companies', {
        slidesPerView: 'auto',
        spaceBetween: 20,
        breakpoints: {
            960: {
                enabled: false,
                spaceBetween: 15,
            },
        },
    });

    const reviewAvatarNavEl = document.querySelectorAll('.swiper--reviews-avatar-nav');

    if (reviewAvatarNavEl) {
        for (const item of reviewAvatarNavEl) {
            const swiperInstanse = new Swiper(item, {
                modules: [Autoplay],
                slidesPerView: 'auto',
                spaceBetween: 20,
                autoplay: {
                    delay: 5000,
                },
                breakpoints: {
                    960: {},
                },
            });
            const reviewsAvatarNav = item.nextElementSibling;

            if (reviewsAvatarNav) {
                const reviewsAvatarNavList = [...reviewsAvatarNav.children];
                reviewsAvatarNavList[0].classList.add('active');

                swiperInstanse.on('slideChange', function (slider) {
                    reviewsAvatarNavList.forEach((item) => item.classList.remove('active'));
                    reviewsAvatarNavList[slider.realIndex]?.classList.add('active');
                });

                reviewsAvatarNavList.forEach((img, index) => {
                    img.addEventListener('click', () => {
                        reviewsAvatarNavList.forEach((item) => item.classList.remove('active'));
                        img.classList.add('active');
                        swiperInstanse.slideTo(index);
                    });
                });
            }
        }
    }

    const swiperReviews = new Swiper('.swiper--reviews', {
        modules: [Navigation],
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        enabled: false,
        breakpoints: {
            768: {
                enabled: true,
            },
        },
    });

    const swiperReviewsFull = new Swiper('.swiper--reviews-full-screen', {
        slidesPerView: 1,
        spaceBetween: 8,
        loop: true,
        breakpoints: {
            768: {
                spaceBetween: 16,
                slidesPerView: 'auto',
            },
        },
    });

    const swiperReviewsTilda = new Swiper('.swiper--reviews-tilda', {
        modules: [Navigation, Pagination, EffectFade],
        autoHeight: true,
        slidesPerView: 1,
        spaceBetween: 20,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    const swiperCourses = document.querySelectorAll('.swiper--courses');

    if (swiperCourses) {
        for (const item of swiperCourses) {
            getSwiperNavigation(item);
        }
    }

    const swiperFeedback = new Swiper('.swiper--feedback', {
        modules: [Navigation],
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        breakpoints: {
            960: {
                spaceBetween: 15,
            },
        },
    });

    const swiperTrackerFeatures = new Swiper('.swiper--tracker-features', {
        modules: [Navigation, Pagination],
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
    });

    const swiperStarsReview = new Swiper('.swiper--stars-review', {
        modules: [Navigation],
        slidesPerView: 'auto',
        spaceBetween: 20,
        navigation: {
            nextEl: '.swiper-button-next-custom',
            prevEl: '.swiper-button-prev-custom',
        },
    });

    setTimeout(() => {
        const swiperTicker = new Swiper('.swiper--ticker', {
            modules: [Autoplay],
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 20,
            loop: true,
            autoplay: {
                delay: 1,
            },
            speed: 6000,
            allowTouchMove: false,
            disableOnInteraction: true,
        });
    }, 2000);

    setTimeout(() => {
        const swiperTickerPhoto = new Swiper('.swiper--ticker-photo', {
            modules: [Autoplay],
            centeredSlides: true,
            slidesPerView: 'auto',
            spaceBetween: 20,
            loop: true,
            autoplay: {
                delay: 1,
            },
            speed: 6000,
            allowTouchMove: false,
            disableOnInteraction: true,
        });
    }, 2000);

    const simpleSliderNodes = document.querySelectorAll('.swiper--simple');
    if (simpleSliderNodes) {
        simpleSliderNodes.forEach((sliderNode) => {
            const slider = new Swiper(sliderNode, {
                slidesPerView: 'auto',
                spaceBetween: 8,
            });
        });
    }
});
