const scrollDownNode = document.getElementById('scrollDown');
const targetScrollBlockNode = document.getElementById('targetScrollBlock');

if (scrollDownNode && targetScrollBlockNode) {
    document.addEventListener('DOMContentLoaded', () => {
        setTimeout(() => {
            scrollDownNode.classList.remove('opacity-0');
        }, 1000);

        scrollDownNode.addEventListener('click', () => {
            const blockBottom = targetScrollBlockNode.getBoundingClientRect().bottom + window.scrollY;

            window.scrollTo({
                top: blockBottom,
                behavior: 'smooth',
            });

            scrollDownNode.classList.add('opacity-0');
        });
    });
}
