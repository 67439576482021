import SlimSelect from 'slim-select';

document.addEventListener('DOMContentLoaded', async function () {
    const selectAge = document.querySelector('#selectAge');

    if (selectAge) {
        const select = new SlimSelect({
            select: selectAge,
            settings: {
                // alwaysOpen: true,
                showSearch: false,
                placeholderText: document.querySelector('#translated_your_age')?.value,
            },
            events: {
                afterChange: function () {
                    select.render.main.main.classList.remove('error');
                },
            },
        });
    }

    const selectCity = document.querySelector('#selectCity');

    if (selectCity) {
        const defaultLocation = document.querySelector('#default_user_location')?.value;

        let placeholderText = '';
        if (defaultLocation) {
            placeholderText = defaultLocation;
        } else {
            placeholderText = document.querySelector('#translated_your_city')?.value;
        }
        let countryList;
        let countryCities;

        const select = new SlimSelect({
            select: selectCity,
            data: [
                {
                    placeholder: true,
                    text: placeholderText,
                },
            ],
            settings: {
                closeOnSelect: false,
                placeholderText: placeholderText,
                // allowDeselect: true,
                // alwaysOpen: true,
            },
            events: {
                beforeOpen: function () {
                    if (select.render.main.values.textContent !== placeholderText) {
                        select.search(select.render.main.values.textContent);
                    }
                },
                afterOpen: function () {
                    const containerRect = select.render.main.main.getBoundingClientRect();
                    select.render.content.main.style.minWidth = containerRect.width + 'px';
                    select.render.content.main.style.width = 'auto';
                    select.render.content.main.style.opacity = '1';

                    select.render.content.search.input.addEventListener('input', (e) => {
                        if (!e.target.value) {
                            select.setData(countryList);
                            countryCities = null;
                        }
                    });
                },
                afterClose: function () {
                    select.render.content.main.style.opacity = '0';
                },
                afterChange: async function (currentData) {
                    select.render.main.main.classList.remove('error');

                    if (countryCities && currentData[0].text !== placeholderText) {
                        select.render.main.main.click();
                        select.search(currentData[0].text);
                        return;
                    } else if (currentData[0].text === placeholderText) {
                        return;
                    }

                    select.render.content.main.classList.add('loading');

                    try {
                        countryCities = await new Promise((resolve, reject) => {
                            fetch(`/get-country-cities?country_code=${currentData[0].value}`, {
                                method: 'GET',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.message !== 'success') {
                                        select.render.main.main.click();
                                        resolve(null);
                                    } else {
                                        select.search(`${currentData[0].text}, `);

                                        resolve(
                                            data.body.map((country) => {
                                                return {
                                                    text: currentData[0].text + ', ' + country.name,
                                                    value: country.code,
                                                };
                                            }),
                                        );
                                    }
                                });
                        });

                        if (countryCities) {
                            countryCities.unshift({
                                placeholder: true,
                                text: placeholderText,
                            });
                            select.setData(countryCities);
                            select.render.content.search.input.focus();
                        }
                    } catch (error) {
                        console.log('error: ', error);
                    } finally {
                        select.render.content.main.classList.remove('loading');
                    }
                },
                // search: (search, currentData) => {},
            },
        });

        countryList = await fetch('/get-countries', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                return data.body.map((country) => {
                    return {
                        text: country.name,
                        value: country.isoCode,
                    };
                });
            });

        countryList.unshift({
            placeholder: true,
            text: placeholderText,
        });
        select.setData(countryList);
    }
});
