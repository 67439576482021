const EventAmplitude = {
    csrfToken: null,
    url: '/api/amplitude/send-event',
    userId: null,
    supportButton: null,

    init() {
        this.supportButton = document.getElementById('support_link');
        this.setCsrfToken();
        if (this.supportButton) {
            this.userId = Number(this.supportButton.getAttribute('data-user-id'));
            this.supportButton.addEventListener('click', this.sendEventClickSupportLink.bind(this));
        }
    },
    sendEventClickSupportLink() {
        // Отправим данные о переходе пользователя на страницу поддержки

        if (this.userId) {
            const body = {
                event_type: 'click_support',
                user_id: this.userId
            }

            fetch(this.url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-Token': this.csrfToken
                },
                body: JSON.stringify(body),
            })
                .then(response => response.json())
                .then(data => {
                    console.log(data);
                })
                .catch((error) => {
                    console.log(error)
                });
        }
    },
    setCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]');

        if (!metaTag) {
            return;
        }

        this.csrfToken = metaTag.getAttribute('content');
    }

}

document.addEventListener("DOMContentLoaded", () => {
    EventAmplitude.init();
});
