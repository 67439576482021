window.EventSendAmplitude = {
    csrfToken: null,
    url: '/api/amplitude/send-event',
    userId: null,
    supportButton: null,

    init() {
        this.setCsrfToken();
    },
    sendEvent(eventName, userId, eventProperties, userProperties) {

        const body = {
            event_type: eventName,
            user_id: userId,
            event_properties: eventProperties,
            user_properties: userProperties
        }

        fetch(this.url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': this.csrfToken
            },
            body: JSON.stringify(body),
        })
            .then(response => response.json())
            .then(data => {
                console.log(data);
            })
            .catch((error) => {
                console.log(error)
            });
    },
    setCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]');

        if (!metaTag) {
            return;
        }

        this.csrfToken = metaTag.getAttribute('content');
    }

}

document.addEventListener("DOMContentLoaded", () => {
    window.EventSendAmplitude.init();
});
