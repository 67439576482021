/* Будем слать запрос на возобновление подписки которая на паузе */
const SubscriptionApp = {
    csrfToken: null,

    pauseInit() {
        const renewButton = document.getElementById('renewalSubscription');
        this.setCsrfToken();
        if (renewButton) {
            renewButton.addEventListener('click', this.sendSubscriptionRenewalRequest.bind(this));
        }
    },
    setCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]');

        if (!metaTag) {
            return;
        }

        this.csrfToken = metaTag.getAttribute('content');
    },
    sendSubscriptionRenewalRequest() {
        const url = '/client/subscription/renewal';
        fetch(url, {
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': this.csrfToken,
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                location.reload();
            })
            .catch((error) => {
                console.log("Error: ", error);
            });
    }
}

document.addEventListener("DOMContentLoaded", () => {
    SubscriptionApp.pauseInit();
});
