import { isElementPartiallyVisible } from './utils/isElementVisible';

document.addEventListener('DOMContentLoaded', function () {
    const floatContainerInner = document.querySelector('.float-container__inner');

    if (floatContainerInner) {
        window.addEventListener('scroll', checkFloatBtn(floatContainerInner));
    }

    const floatContainerHideOnEl = document.querySelector('.container-on-hide-el');

    if (floatContainerHideOnEl) {
        const onHideContainerClass = floatContainerHideOnEl.getAttribute('data-on-hide-el');
        const onHideContainerEl = document.querySelector(`.${onHideContainerClass}`);

        checkElementVisible(onHideContainerEl, floatContainerHideOnEl);

        window.addEventListener('scroll', function () {
            checkElementVisible(onHideContainerEl, floatContainerHideOnEl);
        });
    }
});

function checkElementVisible(onHideContainerEl, floatContainerHideOnEl) {
    if (!onHideContainerEl) {
        return;
    }

    const isOnHideContainerVisible = isElementPartiallyVisible(onHideContainerEl);

    if (isOnHideContainerVisible) {
        floatContainerHideOnEl.classList.add('hidden');
    } else {
        floatContainerHideOnEl.classList.remove('hidden');
    }
}

function checkFloatBtn(floatContainerInner) {
    const floatContainer = document.querySelector('.float-container');

    const elementBounds = floatContainer.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    if (isElementPartiallyVisible(floatContainer)) {
        floatContainerInner.classList.remove('is-fixed');
    } else {
        floatContainerInner.classList[elementBounds.bottom - viewportHeight <= 0 ? 'remove' : 'add']('is-fixed');
    }
}
