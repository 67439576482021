document.addEventListener("DOMContentLoaded", function () {
    const addToOrderBtns = document.querySelectorAll('.btn--add-to-order');

    // if (addToOrderBtns) {
    //     addToOrderBtns.forEach((item) => {
    //         item.addEventListener('click', () => changeAddToOrderBtnState(item));
    //     });
    // }
});

function changeAddToOrderBtnState (btn) {
    btn.classList.toggle('btn--delete');
    btn.classList.toggle('btn--action');

    if (btn.classList.contains('btn--action')) {
        btn.textContent = 'Add to order';
    } else {
        btn.textContent = 'Delete';
    }
}
