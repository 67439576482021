document.addEventListener('DOMContentLoaded', function () {
    const radioFields = document.querySelectorAll('.js-radio');

    if (radioFields.length === 0) {
        return;
    }

    const totalPriceEl = document.querySelector('.js-total-price');

    radioFields.forEach((radioField) => {
        const input = radioField.querySelector('input');
        const totalPrice = input.getAttribute('data-total-price');

        if (input.checked) {
            radioField.classList.add('active');
            totalPriceEl.textContent = totalPrice;
        }

        radioField.addEventListener('click', () => {
            radioFields.forEach((radio) => {
                radio.querySelector('input').checked = false;
                radio.classList.remove('active');
            });

            input.checked = true;
            radioField.classList.add('active');

            totalPriceEl.textContent = totalPrice;
        });
    });
});
