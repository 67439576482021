import { Modal } from 'bootstrap';

document.addEventListener('DOMContentLoaded', function () {
    const jsMatchEmailForm = document.querySelector('.js-match-email');

    if (jsMatchEmailForm) {
        const emailInputs = document.querySelectorAll('.js-match-email-input');
        const myModal = new Modal(document.getElementById('popupEmailDoesntMatch'));
        const submitButton = jsMatchEmailForm.querySelector('button[type="submit"]');

        myModal._element.querySelector('button').addEventListener('click', () => myModal.hide());

        emailInputs.forEach((input) => {
            input.addEventListener('change', () => {
                if (!getEqualInputsValue(emailInputs)) {
                    emailInputs.forEach((input) => input.classList.add('is-error'));
                }
            });
        });

        jsMatchEmailForm.addEventListener('submit', async (e) => {
            e.preventDefault();
            submitButton.disabled = true;

            if (!getEqualInputsValue(emailInputs)) {
                myModal.show();
                submitButton.disabled = false;
                return false;
            }

            const formData = new FormData(document.getElementById('check-email_form'));

            try {
                const response = await fetch('/confirm-email', {
                    method: 'POST',
                    headers: {
                        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                    },
                    body: formData,
                });

                if (!response.ok) {
                    // Если статус не 200-299, выбрасываем ошибку
                    console.error('Error:', response.statusText);
                    submitButton.disabled = false;
                }

                const contentType = response.headers.get('Content-Type');

                if (contentType && contentType.includes('application/json')) {
                    const responseData = await response.json();

                    if (responseData.status) {
                        window.location.href = responseData.redirect_url;
                    }
                } else if (contentType && contentType.includes('text/html')) {
                    window.location.href = response.url ?? '/login';
                }
            } catch (error) {
                console.error('Fetch error:', error);
                submitButton.disabled = false;
            }

            return false;
        });
    }
});

function getEqualInputsValue(inputsEl) {
    const inputValues = [...inputsEl].map((item) => item.value);
    return inputValues.every((item) => item === inputValues[0]);
}
