document.addEventListener('DOMContentLoaded', function () {
    const inputChangeTextBtn = document.querySelector('.input-change-text-btn');

    if (inputChangeTextBtn) {
        function changeText(item) {
            const changeTextBtn = document.querySelectorAll('.change-text-btn');

            changeTextBtn.forEach((btn) => {
                btn.innerHTML = item.checked ? item.dataset.checkedText : item.dataset.uncheckedText;
            });
        }
        inputChangeTextBtn.addEventListener('change', (e) => changeText(e.target));

        changeText(inputChangeTextBtn);
    }
});
