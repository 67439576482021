import * as Sentry from '@sentry/browser';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://0d855032ac865da01fba857fb7a947c1@o1306882.ingest.us.sentry.io/4508923464450048',
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
            Sentry.captureConsoleIntegration(),
        ],
        tracesSampleRate: 1.0,
    });
}
