import Accordion from 'accordion-js';
import StickySidebar from 'sticky-sidebar';
import SimpleBar from 'simplebar';

import { myModalInstance, myModalEl } from './popups';

document.addEventListener('DOMContentLoaded', function () {
    const useOldPageSelectors = document.getElementById('useOldPageSelectors');

    if (useOldPageSelectors) {
        const oldCheckbox = document.getElementById('subscription');

        oldCheckbox.addEventListener('change', () => {
            if (oldCheckbox.checked) {
                const trialPopupAgreeStatus = document.getElementById('trialPopupAgreeStatus');
                trialPopupAgreeStatus.value = 1;
            }

            if (
                !oldCheckbox.checked &&
                (typeof oldCheckbox.dataset.showModal === 'undefined' || oldCheckbox.dataset.showModal)
            ) {
                myModalInstance.show();
            }
        });
    }

    // Смена активного класса для радио
    const radioFields = document.querySelectorAll('.radio-field--quiz .radio-field__input');
    radioFields.forEach((field) => {
        if (field.attributes?.checked) {
            const radioFields = field.querySelectorAll('.radio-field--quiz');
            radioFields.forEach((field) => field.classList.remove('active'));

            field.closest('.radio-field--quiz').classList.toggle('active');
        }
        field.addEventListener('click', radioFieldIconClick);
    });

    function radioFieldIconClick(e) {
        const radioFields = document.querySelectorAll('.radio-field--quiz');
        radioFields.forEach((field) => field.classList.remove('active'));

        const currentTarget = e.currentTarget.closest('.radio-field--quiz');
        currentTarget.classList.toggle('active');
    }

    // Аккордион
    const accordions = document.querySelectorAll('.accordion-default');

    if (accordions.length > 0) {
        accordions.forEach(
            (item) =>
                new Accordion(item, {
                    showMultiple: true,
                }),
        );
    }

    const accordionsFirstOpen = document.querySelectorAll('.accordion-first-open');

    if (accordionsFirstOpen.length > 0) {
        accordionsFirstOpen.forEach(
            (item) =>
                new Accordion(item, {
                    openOnInit: [1],
                }),
        );
    }

    // Сайдбар
    const sidebar = document.getElementById('sidebar');

    if (sidebar) {
        const stickySidebar = new StickySidebar(sidebar, {
            topSpacing: 20,
            bottomSpacing: 20,
            containerSelector: '.sidebar-container',
            innerWrapperSelector: '.sidebar__inner',
        });
    }

    const feedbackPopupScrollContent = document.querySelectorAll('.feedback-popup__content');

    if (feedbackPopupScrollContent.length > 0) {
        feedbackPopupScrollContent.forEach((item) => {
            new SimpleBar(item, {});
        });
    }

    // Формат номера телефона при отправке
    const checkEmailForm = document.getElementById('check-email_form');

    if (checkEmailForm) {
        checkEmailForm.addEventListener('submit', function () {
            const input = checkEmailForm.querySelector('.intl-tel-input');
            input.value = input.value.replace(/\D/g, '');
        });
    }

    // countdown
    if (myModalInstance) {
        const minuteCountdownEl = document.querySelector('.js-minute-countdown');
        let countdownInterval;
        let seconds = 59;

        myModalEl.addEventListener('show.bs.modal', (_) => {
            const cbTimer = () => {
                const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
                minuteCountdownEl.textContent = `00:00:${formattedSeconds}`;

                seconds--;

                if (seconds < 0) {
                    clearInterval(countdownInterval);
                }
            };

            if (!countdownInterval) {
                countdownInterval = setInterval(cbTimer, 1000);
            }
        });

        myModalEl.addEventListener('hide.bs.modal', (_) => {
            if (seconds < 0) {
                clearInterval(countdownInterval);
            }
        });
    }

    const closeFloatBlockBtn = document.querySelector('.js-close-float-block');

    if (closeFloatBlockBtn) {
        closeFloatBlockBtn.addEventListener('click', function () {
            closeFloatBlockBtn.closest('.js-float-block').classList.add('d-none');
        });
    }
});
