import './js/main.js';

import './styles/main.scss';
importAll(require.context('./fonts', true, /\.(woff(2)?|eot|ttf|otf)$/i));

if (process.env.NODE_ENV === 'development') {
    importAll(require.context('./img', true, /\.(gif|png|jpe?g|svg|mp4)$/i));
    // importAll(require.context('./views', true, /\.hbs$/));
}

function importAll(r) {
    r.keys().forEach(r);
}
