import { Modal } from 'bootstrap';

export let myModalInstance;
export let myModalEl;

document.addEventListener('DOMContentLoaded', function () {
    myModalEl = document.getElementById('subscriptionTryModal');

    if (myModalEl) {
        myModalInstance = new Modal(myModalEl);
    }

    setNextOpenPopup('openTryThirtyDaysModal', 'thirtyDaysTrial');
    setNextOpenPopup('openPopupSpecialBtn', 'popupSpecial');

    const continueButtonEl = document.getElementById('continue-adventure');
    const continueButtonSecondEl = document.getElementById('continue-adventure-second');

    if (continueButtonEl) {
        const subscriptionCheckbox = document.getElementById('subscription');

        continueButtonEl.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (continueButtonEl.disabled) {
                return false;
            }

            const trialPopupAgreeStatus = document.getElementById('trialPopupAgreeStatus');

            if (
                myModalEl &&
                trialPopupAgreeStatus.value === '0' &&
                typeof subscriptionCheckbox.dataset.showModal !== 'undefined' &&
                subscriptionCheckbox.dataset.showModal === '1'
            ) {
                myModalInstance.show();
                return false;
            }

            if (subscriptionCheckbox.checked || continueButtonEl.getAttribute('data-activate-subscription')) {
                const continueSpinnerEl = document.getElementsByClassName('continue-spinner');
                if (continueSpinnerEl.length > 0) {
                    continueSpinnerEl[0].style.display = 'inline-block';
                }

                continueButtonEl.classList.add('disabled');
                continueButtonEl.disabled = true;

                purchaseSubscription(e);
            } else {
                skipSubscription(e);
            }

            return false;
        });
    }

    if (continueButtonSecondEl) {
        const subscriptionCheckbox = document.getElementById('subscription');

        continueButtonSecondEl.addEventListener('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            if (continueButtonSecondEl.disabled) {
                return false;
            }

            const trialPopupAgreeStatus = document.getElementById('trialPopupAgreeStatus');

            if (
                trialPopupAgreeStatus.value === '0' &&
                typeof subscriptionCheckbox.dataset.showModal !== 'undefined' &&
                subscriptionCheckbox.dataset.showModal === '1'
            ) {
                myModalInstance.show();
                return false;
            }

            if (subscriptionCheckbox.checked || continueButtonSecondEl.getAttribute('data-activate-subscription')) {
                const continueSpinnerEl = document.getElementsByClassName('continue-spinner');
                if (continueSpinnerEl.length > 0) {
                    continueSpinnerEl[1].style.display = 'inline-block';
                }

                continueButtonSecondEl.classList.add('disabled');
                continueButtonSecondEl.disabled = true;

                purchaseSubscription(e);
            } else {
                skipSubscription(e);
            }

            return false;
        });
    }

    // Попапы для банера с предложением подписки
    const popupCongratulationEl = document.getElementById('popupCongratulation');

    if (popupCongratulationEl) {
        const popupCongratulationInstance = new Modal(popupCongratulationEl);

        window.addEventListener('subscription-successful', (e) => {
            popupCongratulationInstance.show();
        });
        popupCongratulationInstance._element
            .querySelector('button')
            .addEventListener('click', () => popupCongratulationInstance.hide());
    }

    const popupSubErrorEl = document.getElementById('popupSubError');

    if (popupSubErrorEl) {
        const popupSubErrorInstance = new Modal(popupSubErrorEl);

        window.addEventListener('subscription-error', (e) => {
            popupSubErrorInstance.show();
        });
        popupSubErrorInstance._element
            .querySelector('button')
            .addEventListener('click', () => popupSubErrorInstance.hide());
    }

    const cloudPaymentsIframeEl = document.getElementById('cloudPaymentsIframeModal');

    if (cloudPaymentsIframeEl) {
        cloudPaymentsIframeEl.addEventListener('click', (e) => {
            e.stopPropagation();

            const cloudPaymentsIframeInstance = new Modal(cloudPaymentsIframeEl);

            cloudPaymentsIframeInstance.show();

            return false;
        });
    }

    const headerNavbarPopup = document.getElementById('headerNavbar');
    if (headerNavbarPopup) {
        const headerNavbarPopupInstance = new Modal(headerNavbarPopup);

        const headerNavbarBtn = document.querySelector('.headerNavbarBtn');
        if (!headerNavbarBtn) {
            return;
        }

        headerNavbarBtn.addEventListener('click', () => {
            headerNavbarPopupInstance.show();
        });
    }
});

function setNextOpenPopup(btnId, popupId) {
    const openTryThirtyDaysModalButton = document.getElementById(btnId);

    if (openTryThirtyDaysModalButton) {
        const thirtyDaysModal = document.getElementById(popupId);

        openTryThirtyDaysModalButton.addEventListener('click', () => {
            const thirtyDaysModalInstance = new Modal(thirtyDaysModal);
            thirtyDaysModalInstance.show();
        });
    }
}
