import { myModalInstance } from '../popups';

document.addEventListener('DOMContentLoaded', function () {
    const subscriptionFirst = document.getElementById('subscription');
    const subscriptionSecond = document.getElementById('subscription_second');

    if (subscriptionFirst) {
        subscriptionFirst.addEventListener('click', () => {
            if (subscriptionSecond) {
                subscriptionSecond.click();
            }
        });
    }
    if (subscriptionSecond) {
        subscriptionSecond.addEventListener('click', () => {
            if (subscriptionFirst) {
                subscriptionFirst.click();
            }
        });
    }

    const checkboxFieldIcon = document.querySelectorAll('.js-checkbox .input-field__input');

    checkboxFieldIcon.forEach((field) => {
        if (field.checked) {
            field.closest('.js-checkbox').classList.toggle('active');
        }

        const isCheckboxForSubscriptionTryModal = field.closest('.add-free-access__form-checkbox-wrap');
        const isCheckboxTriggerPopup = field.closest('.checkbox-trigger-popup');

        if (isCheckboxForSubscriptionTryModal || isCheckboxTriggerPopup) {
            field.addEventListener('change', () => {
                if (!field.checked) {
                    myModalInstance.show();
                }
            });
        }
        field.addEventListener('click', checkboxFieldIconClick);
    });
});

function checkboxFieldIconClick(e) {
    const currentTarget = e.currentTarget.closest('.js-checkbox');
    currentTarget.classList.toggle('active');

    if (currentTarget.querySelector('#topic_textarea')) {
        currentTarget.querySelector('#topic_textarea').innerHTML = '';
    }
    if (currentTarget.querySelector('#motivation_textarea')) {
        currentTarget.querySelector('#motivation_textarea').innerHTML = '';
    }
    if (currentTarget.querySelector('#reason_textarea')) {
        currentTarget.querySelector('#reason_textarea').innerHTML = '';
    }
}
