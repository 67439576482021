/* Будем покупать новую подписку для пользователя у которого не добавлена карта платежная */
const BuySubscriptionStripe = {
    csrfToken: null,
    subData: {
        subscription_plan_id: null,
        user_session_id: null,
        activity: null,
        email: null,
        public_key_stripe: null
    },
    url: null,

    init() {
        this.setCsrfToken();
        this.registerEvent();

        this.url = '/api/checkout/buy-subscription';
    },
    setCsrfToken() {
        const metaTag = document.querySelector('meta[name="csrf-token"]');

        if (!metaTag) {
            return;
        }

        this.csrfToken = metaTag.getAttribute('content');
    },
    registerEvent() {
        window.addEventListener('buy_subscription_social_user', (e) => {
            let data = e.detail.data_sub;
            if (data) {
                this.setSubData(data);

                this.buySubscription();
            }
        });
    },
    buySubscription() {
        if (this.subData) {
            fetch(this.url, {
                method: 'POST',
                headers: {
                    'X-CSRF-TOKEN': this.csrfToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(this.subData),
            })
                .then(response => response.json())
                .then(data => {
                    let sessionId = data.data.session_id;
                    if (sessionId) {
                        if ($.snack) {
                            let message = 'Redirecting to payment page...';
                            $.snack('success', message, 3000);
                        }
                        let publicKey = this.subData.public_key_stripe;

                        let stripe = Stripe(publicKey);
                        // Call your backend to create the Checkout Session

                        stripe.redirectToCheckout({sessionId: sessionId});
                    }
                })
                .catch((error) => {
                    console.log("Error: ", error);
                });
        }
    },
    setSubData(data) {
        this.subData.subscription_plan_id = data.subscription_plan_id;
        this.subData.user_session_id = data.user_session_id;
        this.subData.activity = data.activity;
        this.subData.email = data.email;
        this.subData.public_key_stripe = data.public_key_stripe;
    }
}

document.addEventListener("DOMContentLoaded", () => {
    BuySubscriptionStripe.init();
});
