document.addEventListener('DOMContentLoaded', function () {
    var heightLimitElements = document.querySelectorAll('.height-limit');

    heightLimitElements.forEach(function (el) {
        var innerH = el.querySelector('.height-limit_inner').offsetHeight;
        var wr = el.querySelector('.height-limit_wr');
        var wrH = wr.offsetHeight;

        if (wrH >= innerH) {
            el.querySelector('.height-limit_toogle').style.display = 'none';
            wr.style.height = 'auto';
            el.classList.add('pb-0');
        } else {
            el.querySelector('.height-limit_toogle').addEventListener('click', function () {
                var limit = this.closest('.height-limit');
                var wr = limit.querySelector('.height-limit_wr');
                var inner = limit.querySelector('.height-limit_inner');
                var innerH = inner.offsetHeight;

                animateHeight(this, 0, 200);
                animateHeight(wr, innerH, 200);
                animateHeight(limit, innerH, 200, 'paddingBottom');
            });
        }
    });

    function animateHeight (element, targetHeight, duration, property) {
        var startHeight = element.offsetHeight;
        var startTime = null;

        function step (currentTime) {
            if (!startTime) {startTime = currentTime;}
            var progress = currentTime - startTime;
            var easeInOutQuad = progress < duration / 2 ? 2 * progress * progress / (duration * duration) : (4 - 2 * progress / duration) * progress / duration - 1;

            element.style.height = startHeight + easeInOutQuad * (targetHeight - startHeight) + 10 + 'px';

            if (property) {
                element.style[property] = easeInOutQuad * targetHeight + 'px';
            }

            if (progress < duration) {
                requestAnimationFrame(step);
            } else if (targetHeight === 0) {
                element.style.display = 'none'; // Скрыть .height-limit_toogle после анимации, если высота устанавливается в 0
            }
        }

        requestAnimationFrame(step);
    }
});
