document.addEventListener("DOMContentLoaded", function () {
    const searchForm = document.querySelector(".search-field");
    const searchFormSearchBtn = searchForm?.querySelector(
        ".search-field__btn--search"
    );
    const searchFormCloseBtn = searchForm?.querySelector(
        ".search-field__btn--close"
    );

    if (searchFormSearchBtn && searchFormCloseBtn) {
        const searchInput = document.querySelector(".search-field__input");

        searchFormSearchBtn.addEventListener("click", function (e) {
            if (window.innerWidth < 992) {
                if (!searchForm.classList.contains("active")) {
                    e.preventDefault();
                    searchForm.classList.add("active");
                    searchInput.focus();
                    return false;
                } else {
                    return true;
                }
            }
        });
    
        searchFormCloseBtn.addEventListener("click", function (e) {
            e.preventDefault();
            searchForm.classList.remove("active");
            return false;
        });
    }
});
