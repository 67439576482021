let lastScrollY = window.scrollY; // Последняя позиция скролла
const header = document.getElementById('floatHeader');

// Обработчик события скролла
const handleScroll = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
        // Скролл вниз, скрыть хедер
        header.classList.add('collapsed');
    } else {
        // Скролл вверх, показать хедер
        header.classList.remove('collapsed');
    }

    lastScrollY = currentScrollY; // Обновить последнюю позицию скролла
};

if (header) {
    window.addEventListener('scroll', handleScroll);
}

const headerSolid = document.getElementById('floatHeaderSolid');

// Обработчик события скролла
const handleScrollHeaderSolid = () => {
    const currentScrollY = window.scrollY;

    if (currentScrollY === 0) {
        // На самом верху страницы
        headerSolid.classList.remove('expanded');
    } else if (currentScrollY > lastScrollY && currentScrollY > 50) {
        // Скролл вниз, скрыть хедер
        headerSolid.classList.add('collapsed');
        headerSolid.classList.remove('expanded');
    } else {
        // Скролл вверх, показать хедер
        headerSolid.classList.remove('collapsed');
        headerSolid.classList.add('expanded');
    }

    lastScrollY = currentScrollY; // Обновить последнюю позицию скролла
};

if (headerSolid) {
    window.addEventListener('scroll', handleScrollHeaderSolid);
}
