document.addEventListener("DOMContentLoaded", function () {
    // Копирование из инпута
    const copyBtns = document.querySelectorAll(".input-field--copy");

    if (copyBtns) {
        copyBtns.forEach((btn) => btn.addEventListener("click", copyText));
        copyBtns.forEach((btn) =>
            btn.addEventListener("mouseleave", copyTextOut),
        );
    }

    function copyText(e) {
        e.preventDefault();
        const inputWrap = e.currentTarget;
        const copyText = inputWrap.querySelector(".input-field__copy-text");
        navigator.clipboard.writeText(copyText.textContent);

        const tooltipText = inputWrap.querySelector(".tooltip-copy__text");
        tooltipText.textContent = document.querySelector("#copied_text").value;
        tooltipText.style.opacity = "1";
        tooltipText.style.visibility = "visible";
        setTimeout(() => {
            tooltipText.style.opacity = "0";
            tooltipText.style.visibility = "hidden";
        }, 1000);
    }

    function copyTextOut(e) {
        const inputWrap = e.currentTarget;
        const tooltipText = inputWrap.querySelector(".tooltip-copy__text");

        tooltipText.style.opacity = "0";
        tooltipText.style.visibility = "hidden";
        tooltipText.textContent = document.querySelector("#copy_text").value;
    }
});
