window.addEventListener('load', function () {
    // const blurBrightEl = document.querySelector('');

    // if (blurBrightEl) {
        // addElement(blurBrightEl);
    // }
});

function addElement(blurBrightEl) {
    const blurBrightRect = blurBrightEl.getBoundingClientRect();
    blurBrightEl.style.zIndex = 10;
    blurBrightEl.style.position = 'relative';

    const el = document.createElement('div');
    el.classList.add('blur-bright-bg-img');

    // Рассчитываем координаты для расположения по центру относительно blurBrightEl
    const centerX = blurBrightRect.left + blurBrightRect.width / 2;
    const centerY = blurBrightRect.top + blurBrightRect.height / 2;

    // Рассчитываем размеры для нового элемента
    const newWidth = blurBrightRect.width + 600;
    const newHeight = blurBrightRect.height + 300;

    el.style.width = `${newWidth}px`;
    el.style.height = `${newHeight}px`;
    el.style.position = 'absolute';
    el.style.top = `${centerY - newHeight / 2}px`;
    el.style.left = `${centerX - newWidth / 2}px`;
    el.style.transform = 'translate(-50%, -50%)';

    blurBrightEl.appendChild(el);
}
