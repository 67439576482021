import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

export function getSwiperNavigation(item) {
    let next = item.querySelector('.swiper-button-next-custom');
    let prev = item.querySelector('.swiper-button-prev-custom');

    const checkNav = () => {
        const isAllSlideVisible =
            (prev && next && window.innerWidth < 992) ||
            (prev &&
                next &&
                swiperInstance &&
                Array.from(swiperInstance.slides).every((item) => item.classList.contains('swiper-slide-visible')));

        if (isAllSlideVisible !== undefined && !isAllSlideVisible && prev && next) {
            next.classList.add('d-flex');
            prev.classList.add('d-flex');
        }
    };

    let swiperCoursesConfig = {
        slidesPerView: 'auto',
        watchOverflow: true,
        watchSlidesProgress: true,
        spaceBetween: 0,
        observer: true,
    };

    if (prev && next) {
        swiperCoursesConfig = {
            ...swiperCoursesConfig,
            modules: [Navigation],
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            on: {
                init: () => checkNav(),
                update: () => checkNav(),
                resize: () => checkNav(),
            },
        };
    }

    let swiperInstance = new Swiper(item, swiperCoursesConfig);

    let intervalId = null;

    const throttledUpdate = () => {
        swiperInstance.update();
        swiperInstance.updateSlides();
        swiperInstance.updateSlidesProgress();
        swiperInstance.updateSlides();
        swiperInstance.slideTo(0);
        swiperInstance.el.parentElement.classList.add('opacity-0');

        setTimeout(() => {
            if (intervalId) {
                clearInterval(intervalId);
            }

            intervalId = setInterval(() => {
                if (
                    !Array.from(swiperInstance.slides).some((item) => item.classList.contains('swiper-slide-visible'))
                ) {
                    swiperInstance.update();
                    swiperInstance.updateSlides();
                    swiperInstance.updateSlidesProgress();
                    swiperInstance.updateSlides();
                    swiperInstance.slideTo(0);
                    swiperInstance.el.parentElement.classList.remove('opacity-0');
                    clearInterval(intervalId);
                } else {
                    swiperInstance.el.parentElement.classList.remove('opacity-0');
                    clearInterval(intervalId);
                }
            }, 500);
        }, 500);
    };

    swiperInstance.on('observerUpdate', throttledUpdate);

    const clearSwiperInterval = () => {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
        }
    };

    return { swiperInstance, clearSwiperInterval };
}
